@use "variables" as *;
@use "mixins" as mix;

html {
    overflow-x: hidden !important;
}

// ----------------
// Buttons
// ----------------
.primaryBtn {
    @include mix.primaryBtn;

    @media (max-width: $breakpoint-sm) {
        &.vAll {
            &:after {
                margin: 0;
            }

            span {
                display: none;
            }
        }
    }

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.secondaryBtn {
    @include mix.secondaryBtn;

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.tertiaryBtn {
    @include mix.tertiaryBtn;

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.fourthBtn {
    @include mix.fourthBtn;

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.primary-bg {
    background: $primary-color;
}

.secondary-bg {
    background: $secondary-color;
}

.tertiary-bg {
    background: $tertiary-color;
}

.grayGradientBg {
    background: linear-gradient(180deg, #ACACAC 0%, rgba(255, 255, 255, 0.00) 73.44%);
}

.blueGradientBg {
    background: linear-gradient(180deg, $tertiary-color 0%, rgba(255, 255, 255, 0.00) 57.01%);
}

.top-rounded-radius {
    @include mix.border-radius(25px 25px 0 0);
}

.secondary-bg {
    background: $secondary-color;
}

.rounded-bottom-4 {
    @include mix.border-radius(0px 0px 25px 25px);
}

// Remove this styling when you start on new-project
// main {
//     height: 100vh;
// }
// .sf_logo {
//     width: 250px;
//     height: 100%;
// }
.container {
    width: 95%;
    max-width: 100%;
}

.arrow {
    @include mix.trans;

    &.swiper-prev {
        position: relative;
        top: 3px;
        @include mix.transform(rotate(-180deg));
    }

    &:hover,
    &:focus,
    &:active {
        opacity: 0.5;
    }
}

.arrow-left {
    &:before {
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/site-assets/svg/black-arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-right: 5px;
        @include mix.transform(rotate(-180deg));
    }

    &.white {
        &:before {
            filter: brightness(0) invert(1);

        }

        &:hover,
        &:focus,
        &:active {
            color: $base-white;
            text-decoration: underline;
        }
    }
}

.swiper-custom-paginations {

    .swiper-pagination-bullet {
        width: 40px;
        height: 7px;
        opacity: 1;
        background: $bg-light;
        margin: 0 0.325rem;
        cursor: pointer;
        @include mix.border-radius(5px);
        @include mix.trans;

        &.swiper-pagination-bullet-active {
            background: $primary-color;
        }

        &:hover,
        &:focus,
        &:active {
            background: $primary-color;
        }
    }

}

.formio-component {
    a {
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    .formio-component-submit {
        text-align: right;
    }

    .formio-component {
        margin-bottom: 1rem;

        &.formio-component-radio,
        &.formio-component-checkbox {
            input {
                padding: 0;
            }
        }

        input {
            border: none;
            padding: 0.875rem 1.2rem;
            @include mix.border-radius(25px);
        }

        textarea {
            border: 1px solid $secondary-color;
        }

        .btn {
            @include mix.primaryBtn;
            border: none;
            margin: 0;

            &:after {
                content: none;
            }
        }

        .required {
            &:before {
                content: "* Required";
                display: block;
                opacity: 0.3;
                font-size: 0.8rem;
            }
        }

        .browse {
            font-weight: 500;
        }

        a {

            &:hover,
            &:focus,
            &:active {
                color: $font-color;
            }
        }

        .list-group-item {
            border-color: $bg-light;
        }

        .fa-cloud-upload {
            display: inline-block;
            background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg>');
            background-repeat: no-repeat;
            width: 1.5rem;
            height: 1.5rem;
            vertical-align: bottom;
            background-size: 20px;
        }

        .fa-remove {
            display: block;
            cursor: pointer;
            background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>');
            background-repeat: no-repeat;
            width: 1.2rem;
            height: 1.2rem;
            vertical-align: sub;
            background-size: 20px;

        }
    }

    .formio-choices {
        &.is-open {
            .choices__list {
                >.choices__list {
                    display: block;
                    box-shadow: 1px 1px 15px $bg-light;
                }
            }
        }

        .choices__list {
            >.choices__list {
                display: none;
            }

            .choices__item {
                padding: 0 1rem;
            }
        }
    }
}

.consultant-pop-content {
    &.fancybox__content {
        background: $base-black;
        color: $base-white;
        border: 4px solid $base-black;
        max-width: 1000px;

        .card-img {
            @include mix.object-fit;
        }

        .post-title {
            color: $primary-color;
        }

        .contacts {
            .nav-item {
                a {
                    margin: 0 0.5rem 0.5rem 0;
                    padding: 0.55rem 0.725rem;
                    background: $bg-light;
                    @include mix.border-radius(50px);
                    @include mix.trans;

                    svg {
                        width: 15px;
                        height: 15px;

                        path {
                            fill: $base-black;
                        }
                    }

                    &:hover,
                    &:active {
                        opacity: 0.5;
                    }
                }
            }
        }

        .f-button {
            opacity: 1 !important;
            top: 5px !important;
            right: 5px !important;
            background: $primary-color;
            color: $base-black;
            @include mix.border-radius(100%);

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5 !important;
                color: $base-black;
                background: $primary-color;
            }

        }
    }
}

.dialog-content {
    &.fancybox__content {
        @include mix.border-radius(25px);
        width: 95%;

        @media (min-width: $breakpoint-md) {
            width: 50%;
            max-width: 450px;
        }

    }

    .formio-component {
        .formio-component {
            input {
                border: 1px solid $bg-light;
            }

            textarea {
                border: 1px solid $bg-light;
            }
        }
    }
}

.z-1 {
    z-index: 1;
}

html {
    &.with-fancybox {

        #onetrust-consent-sdk,
        #ld-chat-app {
            display: none !important;
        }
    }
}

.fancybox__container {
    z-index: 2147483647 !important;
}